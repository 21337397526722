import {action, computed, observable} from "mobx";
import {BriageApi} from "../api";
import * as Tools from '../utils/tools'
import demoStore from './demo.store';
import Stcasc from 'switch-chinese';

const stcasc = new Stcasc();

export class ChatGptStore {

  @observable chatlist = [];

  @computed get waiting() {
    if (this.chatlist.length > 0) {
      return this.chatlist.some(item => item.waiting);
    }
    return false
  }

  @computed get ready() {
    return !!demoStore.config?.system
  }

  @computed get idle() {
    return this.ready && !this.waiting
  }

  @computed get playMessage() {
    if (this.chatlist.length > 0) {
      const chat = this.chatlist[this.chatlist.length - 1];
      if (chat.id && chat.role === 'assistant' && !chat.waiting) {
        return chat
      }
    }
    return null;
  }

  @action
  sendChat = async (text, label) => {
    this.chatlist = [...this.chatlist, {
      role: 'user',
      content: stcasc.traditionalized(text),
      label,
    }, {
      role: 'assistant',
      waiting: true
    }]
    let messages = this.chatlist.filter(item => !item.waiting).map(item => ({role: item.role, content: item.content}))
    if (demoStore.config.messageLimit > 0) {
      messages = messages.filter((_, index) => index >= messages.length - demoStore.config.messageLimit)
    }
    messages = [{
      role: 'system',
      content: demoStore.config.system
    }, ...messages]

    let finish_reason
    do {
      const {choices: [{finish_reason: reason, message}], id} = await BriageApi.chatCompletions(messages);
      finish_reason = reason
      messages.push({id, ...message})
      if (finish_reason === 'tool_calls') {
        for (const tool_call of message.tool_calls) {
          const content = await Tools[tool_call.function.name](JSON.parse(tool_call.function.arguments))
          messages.push({
            role: "tool",
            tool_call_id: tool_call.id,
            name: tool_call.function.name,
            content: JSON.stringify(content)
          })
        }
      }
    } while (finish_reason === 'tool_calls')

    const [result] = messages.reverse()
    this.chatlist = this.chatlist.map((item, index) => {
      return index === this.chatlist.length - 1 ? result : item
    })
  }

  @action
  reset = () => {
    this.chatlist = []
  }

}

export default new ChatGptStore();