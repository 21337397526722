import {ICardApi, CuratorsTravelApi} from "../api";

export const icardHomeData = async () => {
  return await ICardApi.loadHomeData();
}

export const loadCardDetail = async ({question}) => {
  const cardid = await ICardApi.findCardID(question)
  if (!cardid) {
    return {data: "找不到相关内容"}
  }
  return await ICardApi.getCardDetail(cardid)
}

export const loadCardAnnualFee = async ({question}) => {
  const cardid = await ICardApi.findCardID(question)
  if (!cardid) {
    return {data: "找不到相关内容"}
  }
  return await ICardApi.getAnnualFee(cardid)
}

const CACHE = {}

export const loadJapanTravelData = async () => {
  if (!CACHE.travel) {
    const {data: {contentfulTopic, popularPosts}} = await CuratorsTravelApi.getPageData();
    CACHE.travel = [
      ...contentfulTopic.latestPosts,
      ...popularPosts.nodes
    ].map(({name, slug, excerpt, image}) => ({
      name, slug, excerpt, image: image.gatsbyImageData.images.fallback.src
    }))
  }
  return CACHE.travel.map(({slug, ...rest}) => rest)
}

export const loadJapanTravelDetail = async ({title}) => {
  console.log(title)
  const item = CACHE.travel.find(item => item.name.indexOf(title) >= 0)
  const {data: {contentfulPost}} = await CuratorsTravelApi.getPageData(item.slug);
  console.log(contentfulPost?.contents)
  return contentfulPost?.contents || []
}

export default [{
  "type": "function",
  "function": {
    "name": "icardHomeData",
    "description": "获取iCard網站推荐信用卡的数据内容。"
  }
}, {
  "type": "function",
  "function": {
    "name": "loadCardDetail",
    "description": `
      获取iCard网站上指定某一张信用卡的详情信息。
      
      当你无法获取更多信用卡的信息时，调用该工具，可以获取更多的信用卡信息。请从与用户的对话中提取用户想要搜索的信用卡名称作为 question 参数的值。
    `,
    "parameters": {
      "type": "object",
      "required": ["question"],
      "properties": {
        "question": {
          "type": "string",
          "description": "需要获取信用卡详细信息的卡片名称，请从用户的提问或聊天上下文中提取。卡片名称必须是iCard网站上返回的数据。"
        }
      }
    }
  }
}, {
  "type": "function",
  "function": {
    "name": "loadCardAnnualFee",
    "description": `
      获取iCard网站上指定某一张信用卡的年费信息。
      
      当你无法获取更多信用卡的年费信息时，调用该工具，可以获取更多的信用卡年费信息。请从与用户的对话中提取用户想要搜索的信用卡名称作为 question 参数的值。
    `,
    "parameters": {
      "type": "object",
      "required": ["question"],
      "properties": {
        "question": {
          "type": "string",
          "description": "需要获取信用卡详细信息的卡片名称，请从用户的提问或聊天上下文中提取。卡片名称必须是iCard网站上返回的数据。"
        }
      }
    }
  }
}, {
  "type": "function",
  "function": {
    "name": "loadJapanTravelData",
    "description": "获取'私享旅游'网站中关于热门旅游地区的文章列表。"
  }
}, {
  "type": "function",
  "function": {
    "name": "loadJapanTravelDetail",
    "description": `
      获取'私享旅游'网站上指定某一篇文章的详细信息。
      
      当你无法获取更多旅游信息时，调用该工具，可以获取更多的旅游信息。请从与用户的对话中提取用户想要搜索的文章名称名称作为 question 参数的值。
    `,
    "parameters": {
      "type": "object",
      "required": ["title"],
      "properties": {
        "title": {
          "type": "string",
          "description": "需要获取旅游文章详细信息的文章标题，请从用户的提问或聊天上下文中提取。文章标题必须是'私享旅游'网站上返回的数据。"
        }
      }
    }
  }
}]