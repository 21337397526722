import {extend} from 'umi-request';
import {KIMI_PATH} from "../utils/constants";
import tools from '../utils/tools'

const kimi = extend({
  charset: 'utf-8',
  prefix: KIMI_PATH,
})

export const chatCompletions = async (messages) => {
  return await kimi.post('/chat/completions', {
    data: {messages, model: 'moonshot-v1-auto', temperature: 0.3, tools}
  })
}
