import {extend} from 'umi-request';
import {ICARD_PATH} from "../utils/constants";

const icard = extend({
  charset: 'utf-8',
  prefix: ICARD_PATH,
})

export const loadHomeData = async () => {
  const query = `
    query _campaign($id: String!) {
      campaign(id: $id) {
        renderType
        title
        showTitle
        children {
          ...Slide_item
          __typename
        }
        __typename
      }
    }
    
    fragment Slide_item on Campaign {
      title
      subtitles
      __typename
    }
  `
  const variables = {
    "id": "channel_concept/5f840fefd88c1a81dac97ee8"
  }
  return icard.post('/graphql', {
    data: {query, variables}
  })
}

export const findCardID = async (question) => {
  const query = `
  query _findCardTab($question: String) {
    findCardNg(question: $question) {
      ... on FindCardNgResult {
        ...TabField
        debug
        __typename
      }
      ...AskFieldForNonRatableResponse
      __typename
    }
  }
  
  fragment TabField on FindCardNgResult {
    ratableGroups {
      edges {
        cursor
        node {
          ratable {
            id
            name
            __typename
          }
          channel {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  
  fragment AskFieldForNonRatableResponse on AskNgResponse {
    ... on CardList {
      cards {
        id
        bank
        name
        __typename
      }
      debug
      __typename
    }
    ... on CardUsageResponse {
      cardIds
      channelId
      debug
      __typename
    }
    ... on LoanResponse {
      debug
      __typename
    }
    ... on InterestRateResponse {
      debug
      __typename
    }
    ... on UnknownResponse {
      debug
      __typename
    }
    __typename
  }
  `
  const response = await icard.post('/graphql', {
    data: {query, variables: {question}}
  })
  const {findCardNg} = response.data || {};
  if (findCardNg.cardIds) {
    return findCardNg.cardIds[0]
  }
  if (findCardNg.cards) {
    return findCardNg.cards[0].id
  }
}

export const getCardDetail = async (cardId) => {
  const query = `
  query _index($cardId: String!) {
    getCards(ids: [$cardId]) {
      ...hooks_useCardSelector
      __typename
    }
  }
  
  fragment hooks_useCardSelector on Card {
    id
    bank
    name
    aliases
    level
    issuer
    thumbnail
    apply_url
    fee_1st_l
    fee_1st_h
    fee_1st_note
    fee_2nd_l
    fee_2nd_h
    fee_2nd_note
    promotion {
      headlines
      details
      __typename
    }
    top_ratables {
      id
      name
      __typename
    }
    bank_id
    specs
    groups {
      id
      issuer
      level
      user_guide
      __typename
    }
    spotlight {
      strengths {
        title
        brief
        detail
        __typename
      }
      summary
      __typename
    }
    new_rewards {
      ...CardRewardDetailField
      effective_date_b
      __typename
    }
    reward_count
    user_guide
    __typename
  }
  
  fragment CardRewardDetailField on Reward {
    id
    description_short {
      type
      token
      __typename
    }
    detail
    reward_channel_advance
    r_conditions_long
    __typename
  }
  `
  return icard.post('/graphql', {
    data: {query, variables: {cardId}}
  })
}

export const getAnnualFee = async (id) => {
  const query = `
    query _getCard($id: String!) {
      getCards(ids: [$id]) {
        id
        ...Notes_annualFee
        ...Notes_revolving
        __typename
      }
    }
    
    fragment Notes_annualFee on Card {
      apply_condition
      apply_doc
      fee_1st_l
      fee_1st_h
      fee_1st_note
      fee_2nd_l
      fee_2nd_h
      fee_2nd_note
      __typename
    }
    
    fragment Notes_revolving on Card {
      rir
      cash_adv_fee
      __typename
    }
  `
  return icard.post('/graphql', {
    data: {query, variables: {id}}
  })
}


