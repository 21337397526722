import {action, computed, observable} from "mobx";
import {ExtendApi} from "../api";

export class DemoStore {

  @observable datas = []
  @observable activeIndex = -1;

  @computed get config() {
    return this.activeIndex >= 0 && this.activeIndex < this.datas.length && this.datas[this.activeIndex] || {}
  }

  @computed get chats() {
    return this.datas.map(({name, role, avatar, color}) => ({name, role, avatar, color}))
  }

  constructor() {
    this.loadConfig()
  }

  @action
  loadConfig = async () => {
    this.datas = await ExtendApi.loadStaticFile(`config.json?${Math.random()}`, {name: "demo_config"});
  }

  @action
  setActive = (index) => {
    this.activeIndex = index
  }

}

export default new DemoStore();