import jsonp from 'jsonp';
import {STATIC_URI} from "../utils/constants";

export const loadLocalAddress = async () => {
  return new Promise((resolve, reject) => {
    jsonp('https://api.ipify.org/?format=jsonp', {}, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}

export const loadStaticFile = async (path, opts) => {
  return new Promise((resolve, reject) => {
    jsonp(STATIC_URI + path, {name: 'callback', timeout: null, ...(opts || {})}, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}
