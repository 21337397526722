const routes = [{
  path: '/',
  redirect: '/home',
  exact: true
}, {
  path: '/home',
  component: './pages/home.js',
  exact: true
}, {
  path: '/chat',
  redirect: '/chat/0',
  exact: true
}, {
  path: '/chat/:index',
  component: './pages/chat.js',
  exact: true
}];

export default routes;
