import {extend} from 'umi-request';
import {CURATORTRAVEL_PATH} from "../utils/constants";

const curators = extend({
  charset: 'utf-8',
  prefix: CURATORTRAVEL_PATH,
})

export const getPageData = async (path) => {
  const target = ['popular-tourist-areas', path].filter(item => !!item).join('/')
  const response = await curators.get(`/page-data/${target}/page-data.json`)
  return response.result
}
